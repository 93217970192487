import { SVGProps, Ref, forwardRef } from 'react'

const SvgColorGoogle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="#4285F4"
      d="M22.497 12.218a12.9 12.9 0 0 0-.189-2.228H11.965v4.223h5.923a5.076 5.076 0 0 1-2.192 3.332v2.74h3.534c2.075-1.91 3.267-4.73 3.267-8.067Z"
    />
    <path
      fill="#34A853"
      d="M11.965 22.932c2.958 0 5.452-.97 7.27-2.647l-3.535-2.74c-.983.669-2.252 1.048-3.73 1.048-2.861 0-5.287-1.93-6.157-4.525H2.166v2.824a10.967 10.967 0 0 0 9.8 6.04Z"
    />
    <path
      fill="#FBBC04"
      d="M5.809 14.068a6.568 6.568 0 0 1 0-4.199V7.045H2.166a10.975 10.975 0 0 0 0 9.847l3.643-2.824Z"
    />
    <path
      fill="#EA4335"
      d="M11.965 5.34a5.959 5.959 0 0 1 4.207 1.644l3.13-3.13A10.54 10.54 0 0 0 11.966 1a10.964 10.964 0 0 0-9.799 6.044l3.643 2.824C6.675 7.27 9.104 5.34 11.965 5.34Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgColorGoogle)
export default ForwardRef
